<template>
  <div>
    <div class="bg-header">
      <el-form :model="form"
               label-width="80px">
        <el-row :gutter="12">
          <el-col :span="6">

            <el-form-item label="试卷名称:">
              <el-input placeholder="请输入内容"
                        style="width:100%"
                        v-model="form.keyword"
                        clearable>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="科目:">
              <el-select v-model="form.subject_id"
                         clearable
                         @change="subjectChange"
                         style="width:100%"
                         placeholder="选择科目">
                <el-option v-for="item in subjectList"
                           :key="item.subject_id"
                           :label="item.subject_name"
                           :value="item.subject_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="6">
            <el-form-item label="开始时间:">
              <el-date-picker v-model="form.beginTime"
                              style="width:100%"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              placeholder="开始时间" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="结束时间:">
              <el-date-picker v-model="form.endTime"
                              style="width:100%"
                              type="datetime"
                              value-format="yyyy-MM-dd HH:mm:ss"
                              default-time="23:59:59"
                              placeholder="截止时间" />
            </el-form-item>
          </el-col> -->

          <el-col :span="12">

            <el-button type="primary"
                       @click="search">查询</el-button>

          </el-col>
        </el-row>

      </el-form>
    </div>
    <div class="bg-content">

      <div class="in-margin">
        <Table ref="TablesRef"
               :tableData="tableData" />
        <!-- <MyDiaglog :title="title"
               :type="type"
               ref="paperRef" /> -->
        <pagination ref="pagination"
                    :get-data="initData"
                    :now-page.sync="page"
                    :now-size.sync="size"
                    :total="totalElements" />
      </div>
    </div>
    <!-- -->
  </div>
</template>

<script>

import { getSelfGrade } from '@/api/grade.js'
import { getTeacherSubjectList } from '@/api/getSubjectList.js'
import { sheetUrl } from '@/utils/basicUrl.js'

export default {
  data () {
    return {
      title: '',
      type: '',
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      tableLoading: false,
      form: { subject_id: '', keyword: '' },
      gradeList: [],
      gradeList1: ['高三', '高二', '高一'],
      subjectList: [],
     

      pageName: 'teacher_creatr_paper',
      tableColumns: [
        { prop: 'edu_paper_id', align: 'left', label: 'ID', width: '100' },
        { prop: 'paper_name', align: 'left', label: '试卷名称', width: '' },
        // { prop: 'subject_name', align: 'left', label: '科目名称', width: '100' },
        // { prop: 'module_desc', align: 'left', label: '类型', width: '' },
        // { prop: 'grade_id', align: 'left', label: '年级', width: '', isStatus: true },
        { prop: 'create_time', align: 'left', label: '创建时间', width: '' },
        // { prop: 'paper_score', align: 'left', label: '总分', width: '100' },
        // { prop: 'is_collect', align: 'center', label: '是否收藏', width: '', isStatus: true },
        { prop: 'subject_name', align: 'left', label: '科目', width: '' },
        // { prop: 'status', align: 'left', label: '是否已生成答题卡', width: '', isStatus: true },
        {
          prop: 'd', align: 'left', label: '操作', width: '', type: 'text', btns: [

            { name: '答题卡列表', class: 'text_link', event: 'uploadSheet' },
            { name: '制作答题卡', class: 'text_link', event: 'makeSheet' },

          ]
        },
      ],

    }
  },
  components: {
  },
  mounted () {
    // this.getGradeList()
    this.setTablesColums()
    this.getSubjectList()
  },
  activated () {
    // this.initData()
  },
  methods: {
    subjectChange () {
      this.initData()
    },
    correctQuestion (row) {
      this.$router.push('/makeSheet/correctQuestion?edu_paper_id=' + row.edu_paper_id + "&subject_id=" + row.subject_id)
    },
    scoreQuery (row) {
      this.$router.push('/makeSheet/scoreQuery?edu_paper_id=' + row.edu_paper_id)
    },
    isShowLink (prop, value, row) {
      // 是否显示【成绩查询】按钮
      if (prop == 'd') {
        if (row['can_del']) {
          return false
        } else {
          return true
        }
      }
    },
    getBtnStatus (opItem, row) {
      // 是否显示【删除】按钮
      if (opItem.event == "delRow") {
        if (row['can_del']) {
          return true
        } else {
          return false
        }
      }
    },
    async getGradeList () {
      const { data } = await getSelfGrade()
      this.gradeList = data
    },
    async getSubjectList () {
      // this.form.questionSubject = ''

      const { data } = await getTeacherSubjectList()
      this.subjectList = data.list
      // if (this.form.subject_id === '') {
      //   let defaultKey = Object.values(data.list).findIndex(val => val.subject_id == localStorage.getItem('defaultSubjectId'))
      //   defaultKey = defaultKey === -1 ? Object.keys(data.list).filter(key => (value => value !== '')(data.list[key].subject_id))[0] : defaultKey
      //   if (defaultKey === undefined) defaultKey = 0
      //   this.form.subject_id = data.list[defaultKey].subject_id
      // }
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName

      this.$refs.TablesRef.setColumns(this.tableColumns)

    },
    getClass (prop, value, row) {
      if (prop == 'is_collect') {
        return value ? 'pass' : 'normal_status'
      }
      if (prop == 'status') {
        if (value == 0) {
          return 'notpass'
        } else if (value == 3) {
          return 'pass'
        } else {
          return 'normal_status'
        }
      }
      return ''
    },
    getValue (prop, value, row) {
      if (prop == 'grade_id') {
        return this.gradeList1[Number(row['grade_id']) - 1]
      }
      if (prop == 'is_collect') {
        if (value == 1) {
          return '已收藏'
        } else {
          return '未收藏'
        }
      }
      if (prop == 'status') {
        if (value == 0) {
          return '未生成'
        } else if (value == 1) {
          return '已生成'
        }
      }
    },
    initData (page, limit) {
      if (page) {
        this.page = page
      }
      if (limit) {
        this.size = limit
      }
      this.$http({
        url: '/api/v1/tang/sheet_paper',
        method: 'get',
        params: {
          page: this.page,
          limit: this.size,
          // page,
          // limit,
          ...this.form
        }
      }).then(res => {
        this.tableData = res.data.list
        this.totalElements = res.data.count
      })
    },
    showDialog (type) {
      this.type = type
      switch (type) {
        case '1': this.title = "模板组卷"; break;
        case '2': this.title = "整卷出卷"; break;
        case '3': this.title = "智能组卷"; break;
        case '4': this.title = "考点组卷"; break;
        case '5': this.title = "知识点组卷"; break;
      }
      this.$refs.paperRef.dialogVisible = true
      this.$nextTick(() => {
        this.$refs.paperRef.initData()
      })
    },
    toInfo (row) {
      if (row.module == 4) {
        this.$router.push('/testPapercompositionNew/infoKnowledge?edu_paper_id=' + row.edu_paper_id + '&module=' + row.module + '&parent=/makeSheet')
      } else {
        this.$router.push('/testPapercompositionNew/paperInfo?edu_paper_id=' + row.edu_paper_id + '&module=' + row.module + '&parent=/makeSheet')
      }

    },
    search () {
      localStorage.setItem('defaultSubjectId', this.form.subject_id)
      this.$refs.pagination.toFirstPage()
    },
    makeSheet (row) {
      // this.$router.push('/makeSheet/make?id='+row.edu_paper_id+'&sheet_id='+row.sheet_id+'&status='+row.status)
      //cookie.set('cannot_edit', 1)
      //let routeTwo = this.$router.resolve(
      //  {
      //    name: 'answerCard',
      //    query: {paper_id: row.edu_paper_id}
      //  }
      //)
      //window.open(routeTwo.href, '_blank')
      let userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    
      window.open(`${sheetUrl}/#/?czb=1&paper_id=${row.edu_paper_id}&cannot_edit=1&t=${userInfo.token}&rt=${userInfo.refresh_token}&download=1`, '_blank')
    },
    uploadSheet (row) {
      this.$router.push('/makeSheet/sheetList?paper_id=' + row.edu_paper_id)
      window.localStorage.setItem('edu_paper_id', row.edu_paper_id)
    },
    refresh () {
      this.$refs.pagination.shuaxin()
    }
  },


}
</script>

<style lang="scss" scoped>
.el-button--primary.is-plain {
  color: #3f9eff;
  background: #ecf5ff;
  border-color: #b3d8ff;
}
.el-button--primary:focus,
.el-button--primary:hover {
  background: #3f9eff;
  border-color: #3f9eff;
  color: #fff;
}
</style>